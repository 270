<template>
  <div class="">
      <div style='width:402px;height:308px;background-color:#fff;margin:10px auto 0px;border-radius:30px;'>
          <p style='padding:10px 0px 0px 25px;font-size:18px;line-height:40px;font-weight:600;color:#000;'>今日预约采样计划表</p>
            <div class="w-full px-3">
      <ul v-if="newList[0]['code'] != '0'">
        <li
          class="pt-2"
          v-for="(item,index) in newList"
          :key="index"
          @click="goPlan('/todayPlan', 'today', item)"
        >
          <p style='word-wrap: break-word;width:100%;' class="flex items-center text-xs">
            <i class="mr-1 bg-black item_title"></i>
            <span> {{ item.good_name ||'' }}（{{ item.code ||''}}）{{ item.date ||'' }}</span><span style="float:right">&gt;</span>
          </p>
          <p class="text-xs text-gray-400 item_date">{{ item.date ||'' }}</p>
        </li>
      </ul>

      <!-- <ul >
        <li        class="pt-2"                 @click="goPlan('/todayPlan', 'today', item)"       >
          <p style='word-wrap: break-word;width:100%;' class="flex items-center text-xs">
            <i class="mr-1 bg-black item_title"></i>
            <span> name（code）</span>
            <span style="float:right">&gt;</span>
          </p>
       
        </li>
      </ul> -->

      <div class="my-2 text-sm text-gray-400" v-else>
        <img src="../../assets/zanwu.png" style='width:202px;height:132px;margin:60px auto;'>
      </div> 
    </div>
        </div>
         <div style='width:402px;height:368px;background-color:#fff;margin:10px auto 0px;border-radius:30px;'>
          <p style='padding:10px 0px 0px 25px;font-size:18px;line-height:40px;font-weight:600;color:#000;'>明日预约采样计划表</p>
            <div class="w-full px-3">
      <ul v-if="tomorrowList.length > 0">
        <li
          class="pt-2"
          v-for="item in tomorrowList"
          :key="item.ordersn"
          @click="goPlan('/tomorrowPlan', 'tomorrow', item)"
        >
          <p style='word-wrap: break-word;' class="flex items-center text-xs">
            <i class="mr-1 bg-black item_title"></i>
            <span> {{ item.good_name }}（{{ item.code }}）</span>
          </p>
          <p class="text-xs text-gray-400 item_date">{{ item.date }}</p>
        </li>
      </ul>
      <div class="my-2 text-sm text-gray-400" v-else>
        <img style='width:202px;height:132px;margin:60px auto;' src="../../assets/zanwu.png">
      </div>
    </div>
        </div>
    <!-- <div class="px-2 header">今日预约采样计划表</div>

    <div class="header">明日预约采样计划表</div> -->

  </div>
</template>

<script>
import { collectList } from "@/api/collect";
import moment from "moment";
export default {
  data() {
    return {
      list: [],
      newList:[{'good_name':'0','ordersn':'0','code':'0'}],
      tomorrowList: [],
    };
  },
  mounted(){
      this.getTodayCollectList();
    this.getTomorrowCollectList();
},
  created() {
    this.getTodayCollectList();
    this.getTomorrowCollectList();
  },
  methods: {
    goPlan(path, type, id) {
      //跳转时页面
      this.$router.push({
        path,
        query: {
          type,
          id,
        },
      });
    },
    // 获取今日采集计划
    getTodayCollectList() {
      collectList({
        type: 1,
        page: 1,
        limit: 10,
      }).then((res) => {
        if (res.code == 200) {
          this.list = [];
          this.list.push(...res.data.list);
   
          this.newList = this.list.map(arr=>{
            let inhours =Number(arr.start_time.substr(0,2)) 
            let inminutes =Number(arr.start_time.substr(3,2)) 
            let hours = moment().hours();
      let minutes = moment().minutes();
      console.log(inhours)
      console.log('fenge')
      console.log(hours)
      if(inhours>=hours && inminutes >= minutes){
        return arr
      }
            
          })
          if(this.newList[0] == undefined){
            this.newList=[{'good_name':'0','ordersn':'0','code':'0'}]
          }
          console.log(this.newList)
          console.log('list')
        }
      });
    },
    // 获取明日采集计划
    getTomorrowCollectList() {
      collectList({
        type: 2,
        page: 1,
        limit: 10,
      }).then((res) => {
        if (res.code == 200) {
          this.tomorrowList = [];
          this.tomorrowList.push(...res.data.list);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sidebox {
  background: white;
}
.header {
  width: 100%;
  height: 48px;
  line-height: 48px;
  color: #333333;
  background: @bg-color !important;
  padding-left: 20px;
  font-size: 16px;
  font-weight: bold;
}
ul {
  width: 100%;
  box-sizing: border-box;
  li {
    width: 100%;
    word-wrap: break-word;
    word-break: normal;
     list-style:none;
     background-color: #F6F9FE;border-radius:10px;padding:0px 20px;
    i {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
    }
    p:nth-child(1) {
      line-height:52px;
      width: 180px;
      white-space: nowrap;
    }
    p:nth-child(2) {
     
      width: 80px;
      white-space: nowrap;
    }
  }
}
li:hover {
  cursor: pointer;
}
li:hover span {
  color: @primary-color;
}
li:hover i {
  background: @primary-color !important;
}
</style>
