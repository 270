<template>
  <div class="flex items-center justify-end header_box">
    <!-- <div
      class="flex items-center justify-center mr-2 text-sm bg-white rounded-sm cursor-pointer text-primary"
      style="width:118px;height: 32px;"
      @click="goToPage('edit')"
    >数据录入</div>-->
    <img
      src="../../static/index/btn.png"
      class="cursor-pointer"
      alt
      @click="goToPage('discountCoupon')"
    />
    <a-popover placement="bottom">
      <template slot="content">
        <!-- <a-tabs  @change="callback" :size="size">
          <a-tab-pane key="1" tab="通知(4)">
            Content of Tab Pane 1
          </a-tab-pane>
          <a-tab-pane key="2" tab="留言(3)" force-render>
            Content of Tab Pane 2
          </a-tab-pane>
        </a-tabs>-->
        <div class="relative msg_box">
          <!-- <div
            class="absolute top-0 left-0 z-30 flex justify-between tabs_item"
          >
            <span class="px-4 py-2" @click="tabs = 1" :class="tabs == 1 ? 'active' : ''">通知(4)</span>
            <span
              class="px-4 py-2"
              @click="tabs = 2"
              :class="tabs == 2 ? 'active' : ''"
              >留言</span
            >
          </div>
          <div class="absolute bg-gray-300 line"></div>
          <div class="zw"></div> -->
          <div class="info">
            <!-- <div v-show="tabs == 1">
              <div class="flex items-center justify-center pt-20">
                <img src="../../static/index/null.png" alt />
              </div>
              <div class="mt-2 text-sm text-center text-gray-400">
                您已阅完所有通知
              </div>
            </div> -->
            <!-- v-show="tabs == 2" -->
            <div>
              <template v-if="comments.length > 0">
                <div
                  v-for="item in comments"
                  :key="item.id"
                  class="flex items-center py-2"
                >
                  <div
                    class="rounded-full"
                    style="width:30px;height:30px;overflow: hidden;"
                  >
                    <img
                      style="height:100%;width:100%;object-fit:cover"
                      :src="item.avatar"
                      alt="头像"
                    />
                  </div>
                  <div class="pl-1 title_desc" style="flex:1">
                    {{ item.content }}
                  </div>
                  <div
                    style="width:25px;padding: 2px 0px;"
                    class="flex items-center justify-center text-xs text-white rounded bg-primary"
                  >
                    新
                  </div>
                </div>
              </template>
              <template v-else>
                <div>
                  <div class="flex items-center justify-center pt-10">
                    <img src="../../static/index/null.png" alt />
                  </div>
                  <div class="py-4 text-sm text-center text-gray-400">
                    暂无新留言
                  </div>
                </div>
              </template>
            </div>
            <div class="mt-3 bg-gray-300 line"></div>
            <div
              @click="goToPage('information')"
              class="flex items-center justify-center w-full py-2 cursor-pointer"
            >
              查看更多
            </div>
          </div>
        </div>
      </template>
      <div class="flex justify-center px-8">
        <img src="../../static/index/info.png" alt />
      </div>
    </a-popover>
    <img
      class="cursor-pointer"
      src="../../static/index/user.png"
      alt
      @click="goToPage('user')"
    />
  </div>
</template>

<script>
import { commentList } from "@/api/notify";
export default {
  data() {
    return {
      size: "large",
      tabs: 1,
      comments: [],
    };
  },
  created() {
    this.getCommentList();
  },
  methods: {
    callback(val) {
      console.log(val);
    },
    goToPage(path) {
      this.$router.push({ path });
    },
    getCommentList() {
      commentList({
        status: 0,
        page: 1,
        limit: 5,
      }).then((res) => {
        if (res.code == 200) {
          this.comments = [];
          this.comments.push(...res.data.list);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header_box {
  height: 64px;
  align-items: center;
  box-sizing: border-box;
}
.msg_box {
  width: 280px;
}
.tabs_item {
  box-sizing: border-box;
  width: 280px;
  span {
    width: 140px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  .active {
    border-bottom: 3px solid @primary-color;
    color: @primary-color;
  }
  span:hover {
    cursor: pointer;
  }
}
.line {
  height: 2px;
  width: 280px;
  top: 38px;
  left: 0px;
  z-index: 0;
}
.info {
  // height: 270px;
}
.zw {
  height: 40px;
  width: 100%;
}
.title_desc {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
