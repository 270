import request from "@/utils/request";

const notifyApi = {
  commentList: "/admin/MemberLog/commentList", // 获取留言列表
  couponList: "/admin/AdminSetting/couponList", // 优惠券列表
  commentRead: "/admin/MemberLog/commentRead", // 设置留言已读
  reply: "/admin/MemberLog/reply", // 留言回复
};
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 * }
 * @param parameter
 * @returns {*}
 */
// 获取留言列表
export function commentList(parameter) {
  return request({
    url: notifyApi.commentList,
    method: "get",
    params: parameter,
  });
}
// 设置留言已读
export function commentRead(parameter) {
  return request({
    url: notifyApi.commentRead,
    method: "get",
    params: parameter,
  });
}
// 获取优惠券列表
export function setCoupon(parameter) {
  return request({
    url: notifyApi.setCoupon,
    method: "post",
    data: parameter,
  });
}
export function reply(parameter) {
  return request({
    url: notifyApi.reply,
    method: "post",
    data: parameter,
  });
}
