import request from "@/utils/request";

const userApi = {
  myInfo: "/admin/AdminMy/myInfo", // 我的信息
  myEdit: "/admin/AdminMy/myEdit", // 修改信息
};
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function myInfo(parameter) {
  return request({
    url: userApi.myInfo,
    method: "get",
    params: parameter,
  });
}

export function myEdit(parameter) {
  return request({
    url: userApi.myEdit,
    method: "post",
    params: parameter,
  });
}
